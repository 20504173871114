import { Controller } from "stimulus";
import { initSelect2 } from "utils";

export default class extends Controller {
  static targets = [];

  connect() {
    initSelect2(this);
  }
}
