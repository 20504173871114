import { Controller } from "stimulus";
import { initSelect2 } from "utils";
import ax from "packs/axios";

export default class extends Controller {
  static targets = ["ingredientsImage"];

  connect() {
    initSelect2(this);
  }

  extractIngredients() {
    const controller = this;
    const url = `/recipes/extract_ingredients_from_image?image_url=${encodeURIComponent(
      this.ingredientsImageTarget.value
    )}`;
    ax.get(url)
      .then(function (response) {
        controller.resetIngredients();
        controller.autoFillIngredients(response.data.ingredients);
      })
  }

  resetIngredients() {
    const removeIngredientButtons =
      Array.from(document.querySelectorAll('.remove_fields')).filter(e => e.textContent == "remove ingredient");
    removeIngredientButtons.forEach(button => button.click());
  }

  // TODO: prompt chatGPT again for daily dozen category assignments. I
  // could maybe have it work in a single prompt and just add that data to
  // the original response.
  autoFillIngredients(ingredients) {
    console.log("INGREDIENTS FROM AI:", ingredients);

    ingredients.forEach(ingredient => {
      const form = this.addNewIngredientForm();
      this.annotateNewIngredientForm(form, ingredient);
      this.autoFillFood(form, ingredient.name);
      this.autoFillUnit(form, ingredient.unit);
      this.autoFillQuantity(form, ingredient.quantity);
    })
  }

  addNewIngredientForm() {
    const addIngredientButton = document.querySelector(`.add_fields[data-association="ingredient"]`);
    addIngredientButton.click();

    const ingredientForms = document.querySelectorAll(`#daily-dozen-ingredients .nested-fields`);
    const newIngredientForm = ingredientForms[ingredientForms.length - 1]

    return newIngredientForm;
  }

  annotateNewIngredientForm(form, ingredient) {
    const formLabels = $(form).find('label').toArray();
    const foodLabel = formLabels.find(label => label.textContent == "Food");
    const unitLabel = formLabels.find(label => label.textContent == "Unit");
    const quantityLabel = formLabels.find(label => label.textContent == "Quantity");

    foodLabel.textContent = `Food (From AI: ${ingredient.name} )`
    unitLabel.textContent = `Unit (From AI: ${ingredient.unit} )`
    quantityLabel.textContent = `Quantity (From AI: ${ingredient.quantity} )`
  }

  autoFillFood(form, ingredientName) {
    this.autoFillFormSelect(form, 'food_id', ingredientName)
  }

  autoFillUnit(form, unit) {
    this.autoFillFormSelect(form, 'unit', unit);
  }

  autoFillFormSelect(form, name_matcher, value) {
    const $select = $(form).find(`select[name*="[${name_matcher}]"]`);
    const select2Obj = $select.data('select2');
    const $search = select2Obj.dropdown.$search;
    const options = select2Obj.$element.children().toArray();

    $select.select2('open');
    $search.val(value).trigger('input');

    const $searchResults = select2Obj.$results.children();
    const bestMatch =  $searchResults[0].textContent;
    const matchingOption = options.find(e => e.label == bestMatch);

    if (matchingOption != null) {
      $select.val(matchingOption.value).trigger("change");
    }

    $select.select2('close');

  }

  autoFillQuantity(form, quantity) {
    const $input = $(form).find(`input[name*="[quantity]"]`);

    $input.val(quantity);
  }

}
